<template>
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
                class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
        <div class="mt-6 flex items-center justify-between px-6">
            <h4>Actualizar cliente</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
        </div>
        <vs-divider class="mb-0"></vs-divider>

        <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
            <div class="p-6">
                <!-- Name -->
                <div class="mt-2">
                    <vs-input @blur="client.name= trimString(client.name)" label="Nombre"
                              v-model="client.name"
                              class="w-full" name="name"
                              v-validate="'required'"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('name')">{{ errors.first('name') }}</span>
                </div>
                <!--End-->
                <!-- Test -->
                <vs-textarea rows="8" @blur="client.test= trimString(client.test)" label="Testimonio"
                             v-model="client.test" class="mt-5 w-full"
                             name="testimonial"
                             v-validate="'required|min:5|max:1000'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('testimonial')">{{ errors.first('testimonial') }}</span>
                <!--End-->
                <!-- Order -->
                <div class="mt-2">
                    <vs-input label="Orden"
                              v-model.number="client.order"
                              class="w-full" name="order"
                              v-validate="'required'"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('order')">{{ errors.first('order') }}</span>
                </div>
                <!--End-->
                <!--Image-->
                <div class="mt-5">
                    <template v-if="client.image">
                        <!-- Image Container -->
                        <div class="img-container flex items-center justify-center">
                            <img :src="client.image" alt="img" style="height: 70px">
                        </div>
                        <!-- Image upload Buttons -->
                        <div class="modify-img flex justify-between mt-5">
                            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg"
                                   accept="image/*">
                            <span style="cursor: pointer" class="ml-2" type="flat"
                                  @click="$refs.updateImgInput.click()">Cambiar
                                </span>
                            <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                  @click="deleteImage">Eliminar</span>
                        </div>
                    </template>
                </div>
                <div class=" mt-5">
                    <!-- Upload image  -->
                    <div class="upload-img" v-if="!client.image">
                        <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg"
                               accept="image/*">
                        <vs-button @click="$refs.uploadImgInput.click()">Imagen (160 x 140)</vs-button>
                    </div>
                </div>
                <!--End image-->
            </div>
        </component>

        <div class="flex flex-wrap items-center p-6" slot="footer">
            <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Actualizar</vs-button>
            <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
            <div v-if="progress" style="margin-top: 1rem; width: 100%">
                <vs-progress indeterminate color="primary"></vs-progress>
            </div>
        </div>
    </vs-sidebar>
</template>

<script>
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import trimString from "../../mixins/trimString"

  const firebase = require("firebase")
  // Required for side-effects
  require("firebase/firestore")
  let db = firebase.firestore()
  export default {
    name: 'UpdateClient',
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        }
      }
    },
    components: {
      VuePerfectScrollbar
    },
    mixins: [trimString],
    data() {
      return {
        client: {},
        settings: {
          maxScrollbarLength: 60,
          wheelSpeed: .60
        },
        newImage: null,
        progress: false
      }
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return
        this.client = {
          ...this.data
        }
        this.$validator.reset()
      }
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
          }
        }
      },
      isFormValid() {
        return !this.errors.any()
      },
      scrollbarTag() {
        return this.$store.getters.scrollbarTag
      }
    },
    methods: {
      submitData() {
        this.$validator.validateAll().then(async result => {
          try {
            if (result) {
              this.progress = true
              if (this.newImage != null) {
                this.client.image = await this.submitImages(this.newImage)
              }
              // Member ref
              let clientRef = db.collection('clients').doc(this.client.id)
              // Clone Member and remove id
              let cloneClient = Object.assign({}, this.client)
              delete cloneClient.id
              // Update Member
              await clientRef.update({
                ...cloneClient,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid: 1
              })
              let obj = {
                ...this.client
              }
              await this.$store.dispatch('clients/updateClient', {client: obj})
              this.$vs.notify({
                color: 'success',
                title: 'Cliente',
                text: 'Cliente actualizado correctamente.'
              })
              this.$emit('closeSidebar')
            }
          } catch (e) {
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          } finally {
            this.progress = false
          }
        })
      },
      submitImages(image) {
        return new Promise((resolve, reject) => {
          let imageName = Math.random().toString(36).substr(1, 20)
          let fileExtension = '.' + image.name.split('.').pop()
          let imageNameFinal = imageName + fileExtension

          let storageRef = firebase.storage().ref(`clients/${imageNameFinal}`)
          storageRef.put(image)
           .then((snapshot) => {
             snapshot.ref.getDownloadURL().then((url) => {
               resolve(url)
             })
           })
           .catch((error) => {
             reject(error)
           })
        })
      },
      updateCurrImg(input) {
        if (input.target.files && input.target.files[0]) {
          const reader = new FileReader()
          reader.onload = e => {
            this.client.image = e.target.result
            this.newImage = input.target.files[0]
          }
          reader.readAsDataURL(input.target.files[0])
        }
      },
      deleteImage() {
        this.client.image = null
        this.newImage = null
      }
    }
  }
</script>

<style lang="scss" scoped>
    .add-new-data-sidebar {
        ::v-deep .vs-sidebar--background {
            z-index: 52010;
        }

        ::v-deep .vs-sidebar {
            z-index: 52010;
            width: 400px;
            max-width: 90vw;

            .img-upload {
                margin-top: 2rem;

                .con-img-upload {
                    padding: 0;
                }

                .con-input-upload {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    .scroll-area--data-list-add-new {
        // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
        height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

        &:not(.ps) {
            overflow-y: auto;
        }
    }
</style>
